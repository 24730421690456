@import 'styles/breakpoints';

.blockCTAGroup {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: var(--block-padding-end) 2.4rem;
    color: var(--colors-blue);

    &.flushTop {
        padding-top: 0;
    }
}

.inner {
    display: flex;
    max-width: 32.8rem;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.eyebrow {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.copy {
    padding-top: 3.6rem;
    text-align: center;
}

.buttons {
    position: relative;
    display: flex;
    width: 100%;
    max-width: 28.2rem;
    flex-direction: column;
    align-items: center;
    margin-top: 3.2rem;
    gap: 1.2rem;
}

@include medium {
    .inner {
        max-width: none;
    }

    .copy {
        max-width: 39rem;
    }

    .buttons {
        position: relative;
        width: 100%;
        max-width: none;
        flex-direction: row;
        justify-content: center;
        margin-top: 2.4rem;
    }
}
