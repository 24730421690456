@import 'styles/breakpoints';
@import 'styles/animations';
@import 'styles/mixins';
@import 'styles/colors';

$ncd-pastel-orange: #ffb653;

@keyframes bounce-image {
    0% {
        scale: 0.75;
    }

    50% {
        scale: 1.05;
    }

    100% {
        scale: 1;
    }
}

@keyframes pulse {
    0% {
        opacity: 1;
        transform: scale(0.95);
    }

    70% {
        opacity: 0;
        transform: scale(1.8);
    }

    100% {
        opacity: 0;
        transform: scale(0.95);
    }
}

@keyframes fade-up {
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.root {
    position: relative;
    overflow: hidden;
    min-height: 50rem;
    margin: var(--block-padding-end) 0;

    &.flushTop {
        margin-top: 0;
    }

    &.flushBottom {
        margin-bottom: 0;
    }

    &.theme-cream-cheese {
        --block-padding: var(--block-padding-end) 2.4rem;

        background-color: var(--colors-ccBlueLight);
        color: var(--colors-blue);
    }

    &.theme-slices {
        --mask-height: 6rem;
        --inner-padding: var(--block-padding-end) 2.4rem
            calc(var(--block-padding-end) + calc(0.75 * var(--mask-height)));

        background: url('//images.ctfassets.net/j8tkpy1gjhi5/CuoSO7cdlsOz1HrxgGkID/2bbbc1c891ad9158355d97eb927327b6/bg-texture-grain.png?fm=webp&q=80'),
            radial-gradient(#ffb548 0%, #f18a00 100%);
        background-blend-mode: overlay, normal;
        color: var(--colors-blue);

        &::after {
            @include ncdTextureCreamDark;

            position: absolute;
            bottom: -0.1rem;
            left: -0.1rem;
            width: 101%;
            height: var(--mask-height);
            content: '';
            mask-image: url('/images/campaigns/slices-23/wavy-border.svg');
            mask-position: bottom;
            mask-repeat: no-repeat;
            mask-size: cover;
            transform: rotate(180deg);
        }
    }

    &.theme-shreds {
        background: radial-gradient(
                77.47% 35.43% at 49.87% 66.29%,
                rgba(254, 230, 147, 70%) 0%,
                rgba(255, 182, 83, 70%) 100%
            ),
            $ncd-pastel-orange;
        color: var(--colors-blue);
    }

    &.theme-holiday {
        .eyebrow,
        .headline,
        .copy,
        .cta {
            animation-duration: 0.3s;
            animation-fill-mode: forwards;
            animation-timing-function: $easeOutQuad;
            opacity: 0;
            transform: translateY(2rem);
        }

        .eyebrow,
        .headline {
            animation-delay: 0s;
        }

        .copy,
        .cta {
            animation-delay: 0.18s;
        }
    }

    &.isInView.theme-holiday {
        .eyebrow,
        .headline,
        .copy,
        .cta {
            animation-name: fade-up;
        }
    }
}

.inner {
    .halfImageLayout & {
        position: relative;
        display: flex;
        width: 100%;
        max-width: var(--block-max-width-plus-padding);
        height: 100%;
        min-height: 0;
        flex-direction: column-reverse;
        padding: var(--inner-padding);
        gap: min(10%, 12rem);

        @include flex-center;
    }

    .theme-cream-cheese & {
        padding: var(--block-padding);
    }

    .theme-shreds & {
        padding: 5rem 2.7rem 0;
    }

    .theme-holiday & {
        position: relative;
        max-width: var(--block-max-width-plus-padding);
    }
}

.contentWrapper {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;

    .halfImageLayout & {
        position: static;
        width: auto;
    }
}

.contentInner {
    position: relative;
    width: 100%;
    height: 100%;

    .halfImageLayout & {
        position: static;
    }
}

.content {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    padding: var(--block-padding-end) 2.4rem;
    color: var(--textColor);
    opacity: 1;
    transition: opacity $default $easeOutQuad;

    &.hide {
        z-index: 0;
        opacity: 0;
    }

    .halfImageLayout & {
        position: relative;
        padding: 0;
    }

    .theme-holiday & {
        z-index: 9;
        height: auto;
    }
}

.icon {
    width: 6rem;
    height: 6rem;
    margin-bottom: 1.2rem;
}

.backgroundImage {
    position: relative;
    width: 100%;
    height: 100%;
}

.halfImage {
    width: 100%;
    max-width: 48rem;
    border-radius: 100%;
    object-fit: contain;
    object-position: center;
    opacity: 0;
    scale: 0.75;
    transition: opacity $easeOutQuad 0.1s;

    .isInView & {
        animation-duration: 0.75s;
        animation-fill-mode: forwards;
        animation-iteration-count: 1;
        animation-name: bounce-image;
        opacity: 1;
    }

    .theme-shreds & {
        max-width: none;
    }
}

.eyebrow {
    margin-bottom: 1.2rem;
    text-transform: uppercase;

    .theme-slices & {
        @include slices-eyebrow;
    }
}

.preHeadline {
    line-height: 0.85;
    text-transform: uppercase;

    .theme-shreds & {
        font-size: 3rem;
    }
}

.headline {
    margin-bottom: 2.4rem;
    text-transform: uppercase;

    &-block {
        @include block-text(var(--colors-cream));

        line-height: 0.88;
    }

    .halfImageLayout & {
        max-width: 40rem;
        padding-right: 4rem;
    }

    .theme-shreds & {
        max-width: none;
        font-size: 4.8rem;
        line-height: 85%;
    }
}

.copy {
    max-width: 50rem;
    margin-bottom: 0;

    .theme-cream-cheese & {
        font-size: 1.8rem;
        font-weight: 500;
        line-height: 130%;
    }
}

.cta {
    z-index: 10;
    width: max-content;
    margin-top: 2.4rem;
}

.interactive {
    position: relative;
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;

    .halfImageLayout & {
        max-width: 48rem;
        padding: 3.5rem 0;
    }

    .theme-shreds & {
        width: 150%;
        max-width: 60rem;
        padding: 0;
    }
}

.labels {
    position: absolute;
    z-index: 1;
    top: 22%;
    left: 50%;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transform: translate(-50%, 45%);
    transition: opacity $easeOutQuad 0.4s 1s, transform $bounce 0.5s 1s;

    .isInView & {
        opacity: 1;
        transform: translate(-50%, 0) scale(1);
    }
}

.buttons {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.hotspotButton {
    position: absolute;
    z-index: 10;
    top: var(--pos-y);
    left: var(--pos-x);
    opacity: 0;
    transform: translateY(45%) scale(0.8);
    transition: opacity $easeOutQuad 0.4s
            calc((0.1s + var(--stagger-order) * 0.1s) + 0.5s),
        transform $bounce 0.5s calc((0.1s + var(--stagger-order) * 0.1s) + 0.6s);

    &::before {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 0.1rem solid var(--hotspot-color);
        border-radius: 50%;
        animation: pulse 2s infinite;
        animation-delay: calc((0.3s + var(--pulse-order) * 0.5s) + 4s);
        content: '';
    }

    .isInView & {
        opacity: 1;
        transform: translateY(0) scale(1);
    }
}

// Clamp font-sizes and reduce spacing for small screens
@media (max-width: 375px) {
    .headline {
        margin-bottom: 1.6rem;
        font-size: clamp(2.4rem, 0rem + 9.6vw, 3.6rem);
    }

    .copy {
        font-size: clamp(1.2rem, 0rem + 4.8vw, 1.8rem);
    }
}

@media (min-width: 550px) {
    .root {
        min-height: unset;
    }

    .backgroundImage {
        aspect-ratio: 0.66;
        object-fit: cover;
        object-position: bottom;
    }

    .hotspotButton {
        top: calc(var(--pos-y) - 4rem);

        .halfImageLayout & {
            top: var(--pos-y);
        }
    }
}

@include mediumOnly {
    .root {
        &.theme-holiday {
            --block-padding: var(--block-padding-end) 2.4rem;
        }
    }
}

@include medium {
    .root {
        display: flex;
        min-height: 35rem;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        &.theme-slices {
            --mask-height: 7rem;
            --inner-padding: var(--block-padding-end) var(--block-padding-side)
                calc(var(--block-padding-end) + calc(0.75 * var(--mask-height)));
        }

        &.theme-cream-cheese {
            --block-padding: var(--block-padding-end) var(--block-padding-side);
        }

        &.theme-shreds {
            background: radial-gradient(
                    62.88% 110.43% at 64.41% 37.87%,
                    rgba(254, 230, 147, 70%) 0%,
                    rgba(255, 182, 83, 70%) 100%
                ),
                $ncd-pastel-orange;
        }
    }

    .backgroundImage {
        aspect-ratio: unset;
        object-fit: unset;
        object-position: unset;
    }

    .hotspotButton {
        top: var(--desktop-pos-y);
        left: var(--desktop-pos-x);
    }

    .inner {
        .theme-shreds & {
            padding: 8rem 8rem 0;
        }
    }

    .contentWrapper {
        .theme-shreds & {
            flex-basis: 30%;
            align-self: flex-start;
        }
    }

    .content {
        display: flex;
        max-width: 50rem;
        height: 100%;
        justify-content: center;
        padding: var(--block-padding);

        .halfImageLayout & {
            margin: 0 auto;
        }

        .theme-holiday & {
            max-width: 35rem;
            height: 100%;
            padding-right: 0;
        }
    }

    .preHeadline {
        .theme-shreds & {
            font-size: 6rem;
        }
    }

    .headline {
        padding-right: 0;

        .theme-shreds & {
            font-size: 12rem;
        }
    }

    .copy {
        max-width: 40rem;

        .halfImageLayout & {
            max-width: 50rem;
        }

        .theme-holiday & {
            max-width: 34rem;
        }
    }

    .cta {
        margin-top: 3rem;
    }

    .interactive {
        .theme-shreds & {
            max-width: 80rem;
        }
    }
}

@include large {
    .root {
        min-height: 40rem;

        &.theme-slices {
            --mask-height: 8rem;
        }
    }

    .icon {
        width: 8rem;
        height: 8rem;
    }

    .inner {
        .halfImageLayout & {
            flex-direction: row-reverse;
            justify-content: space-between;
        }

        .theme-shreds & {
            padding-top: 0;
            padding-right: 0;
        }
    }

    .interactive {
        max-width: none;

        .halfImageLayout & {
            max-width: none;
            flex-basis: 50%;
            flex-shrink: 1;
            padding: 3.5rem;
        }

        .theme-shreds & {
            flex-basis: 70%;
            transform: translateX(4rem);
        }

        .theme-cream-cheese & {
            max-width: 64rem;
            padding: 0;
        }
    }

    .halfImage {
        max-width: 64rem;
    }

    .eyebrow {
        .halfImageLayout & {
            font-size: 2rem;
        }
    }

    .preHeadline {
        line-height: 1;
    }

    .headline {
        margin-bottom: 3rem;

        &-block {
            margin-bottom: 3.6rem;
            font-size: 12rem;
            line-height: 0.85;
        }

        .halfImageLayout & {
            max-width: 50rem;
            padding-right: 0;
        }
    }

    .contentWrapper {
        .halfImageLayout & {
            width: auto;
            max-width: 80rem;
        }

        .theme-shreds & {
            align-self: center;
        }
    }

    .content {
        .theme-holiday & {
            max-width: 40rem;
        }
    }

    .copy {
        max-width: 43.4rem;

        .halfImageLayout & {
            max-width: 43.4rem;
            font-size: 2.4rem;
        }
    }
}

@include xLarge {
    .root {
        .theme-slices & {
            --mask-height: 9rem;
        }
    }

    .inner {
        .halfImageLayout & {
            gap: 20rem;
        }

        .theme-shreds & {
            gap: 0;
        }
    }

    .content {
        max-width: 70rem;

        .theme-holiday & {
            max-width: 55rem;
        }
    }

    .copy {
        .theme-holiday & {
            max-width: 43.4rem;
        }
    }
}

@include xxLarge {
    .root {
        max-height: calc(100vh - 8rem);
    }

    .interactive {
        .halfImageLayout & {
            flex-basis: unset;
        }
    }

    .halfImage {
        max-width: none;
    }
}

@include blockMaxWidthPlusPadding {
    .theme-holiday {
        .inner {
            overflow: hidden;
            max-height: calc(100vh - 8rem);

            @include rounded-corners;
        }
    }
}
