@import 'styles/breakpoints';
@import 'styles/mixins';

.blockCardTrioSmall {
    position: relative;
    width: 100%;
    padding: var(--block-padding-end) 2.4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: var(--colors-blue);

    &.flushTop {
        padding-top: 0;
    }
}

.inner {
    width: 100%;
    max-width: var(--block-max-width);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.eyebrow {
    width: 100%;
    padding-bottom: 3.6rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.cards {
    width: 100%;
    list-style-type: none;
    margin: 0;
    padding: 0;
    max-width: 84.2rem;
}

.listItem {
    @include rounded-corners($overflow: hidden);
}

.listItemGap {
    height: 1rem;
}

@include medium {
    .blockCardTrioSmall {
        position: relative;
        width: 100%;
        padding: var(--block-padding-end) var(--block-padding-side);
    }

    .eyebrow {
        padding-bottom: 4.8rem;
    }

    .cards {
        display: flex;
    }

    .listItemGap {
        height: auto;
        width: 1.2rem;
    }

    .listItem {
        width: 33.3333%;
        margin: 0;

        &:last-child {
            padding-right: 0;
        }
    }
}
