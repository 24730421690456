@import 'styles/colors';
@import 'styles/animations';
@import 'styles/breakpoints';
@import 'styles/mixins';

$dur: 0.6s;

.card {
    position: absolute;
    display: flex;
    width: var(--card-width, 100%);
    height: var(--card-height, auto);
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: var(--card-padding, 4.8rem 2.4rem);
    background-color: var(--card-background-color, transparent);
    color: var(--colors-blue);
    opacity: 0;
    pointer-events: none;
    transition: opacity $easeOutCubic $dur;

    @include rounded-corners;

    &.isPrev {
        opacity: 0;
        transition: opacity $easeInQuad $dur;
    }

    &.isActive {
        opacity: 1;
        pointer-events: auto;
        transition: opacity $easeOutQuad 0.9s 0.57s;
    }

    &:has(.cta) {
        --body-padding: 2rem 0;
    }
}

.eyebrow {
    margin: var(--eyebrow-margin, 0 0 2rem);
    text-transform: uppercase;

    p {
        margin: 0;
    }
}

.headline {
    text-transform: uppercase;
}

.bodyCopy {
    max-width: var(--body-copy-max-width, 32.7rem);
    padding: var(--body-padding, 2rem 0 0);

    p {
        margin: 0 0 1rem;

        &:last-child {
            margin: 0;
        }
    }
}

@include medium {
    .card {
        --card-padding: 4.8rem 1.2rem;
        --body-copy-max-width: 43rem;
    }
}

@include large {
    .card {
        --card-background-color: var(--colors-white);
        --card-width: auto;
        --card-height: calc(100% - 12rem);
        --body-padding: 2.4rem 0 0;
        --body-copy-max-width: 90%;

        z-index: 0;
        max-width: 49.2rem;
        max-height: 57rem;

        &:has(.cta) {
            --body-padding: 2.4rem 0;
        }

        animation-duration: 0.6s;
        animation-fill-mode: forwards;
        animation-timing-function: $easeOutQuad;

        &.isPrev {
            z-index: 1;
            animation-name: swipe-next;

            &.dirNext {
                animation-name: swipe-prev;
            }
        }

        &.isActive {
            animation-duration: 0.6s;
            animation-name: set-active;
            animation-timing-function: $bounce;
            pointer-events: auto;
        }
    }
}

@include xLarge {
    .card {
        --card-padding: 4.8rem;
    }
}

@include xxLarge {
    .card {
        --eyebrow-margin: 0 0 2.4rem;
        --card-padding: 8.5rem 6rem;
    }
}

@include xxxLarge {
    .card {
        width: 62.4rem;
        height: 80rem;

        --body-copy-max-width: 80%;
    }
}

@keyframes set-active {
    from {
        z-index: 1;
        opacity: 0;
        transform: scale(0.85);
    }

    to {
        z-index: 2;
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes swipe-prev {
    from {
        z-index: 2;
        opacity: 1;
        transform: translate(0, 0);
    }

    to {
        z-index: 1;
        opacity: 0;
        transform: translate(-100%, -50%) translate(-50%, -5%) rotate(45deg);
    }
}

@keyframes swipe-next {
    from {
        z-index: 2;
        opacity: 1;
        transform: translate(0, 0);
    }

    to {
        z-index: 1;
        opacity: 0;
        transform: translate(50%, -50%) translate(50%, -5%) rotate(-45deg);
    }
}
