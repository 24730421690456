@import 'styles/animations';
@import 'styles/breakpoints';

$dur: 0.6s;

.root {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: var(--colors-cream);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    opacity: 0;

    &.isShowInitial {
        opacity: 1;
        transition: opacity $easeOutCubic $dur $dur;
    }

    &.isPrev {
        z-index: 2;
    }

    &.isActive {
        z-index: 1;
    }
}

.imageWrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: var(--imageWrapper-width, 100%);
    height: 100%;

    opacity: 0;
    transform: scale(1);
    transition: none;

    display: flex;
    align-items: flex-start;
    justify-content: center;

    .isPrev & {
        opacity: 0;
        transform: scale(1.2);
        transition: opacity $easeInQuad 0.9s, transform $easeInQuad 0.9s;
    }

    .isActive & {
        opacity: 1;
        transition: none;
    }
}

.imageWrapperInner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: scale(1);
    display: flex;
    align-items: flex-start;
    justify-content: center;

    .isPrev & {
        transition-timing-function: $easeOutQuad;
        transition-property: transform;

        transform: scale(1.15);
    }

    .isActive & {
        transition-timing-function: $easeOutQuad;
        transition-property: transform;

        transform: scale(1.15);
    }
}

.image {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    max-width: none;
    object-fit: cover;
}

.column {
    position: absolute;
    top: 0;
    right: 0;
    width: var(--column-width, 100%);
    height: var(--column-height, 100%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 1;

    &:last-child {
        left: 0;
        top: var(--column-top, 50%);
        height: 50%;
        transform: translateY(-4rem);
    }
}

.textLockup {
    text-transform: uppercase;
    width: var(--textLockup-width, 100%);
    position: absolute;
}

.headline {
    white-space: pre;
    margin-top: 2rem;
    padding-bottom: var(--headline-padding-bottom, 26rem);
}

.headlineLineWrap,
.eyebrowWrap {
    position: relative;
    overflow: hidden;
}

.eyebrow {
    transition: transform linear 1.1s;
    transform: translateY(100%);
    visibility: hidden;

    .isPrev & {
        visibility: visible;
        transform: translateY(-100%);
        transition: transform linear 1.1s;
    }

    .isActive & {
        visibility: visible;
        transform: translateY(0%);
        transition: transform linear 1.1s;
    }
}

.headlineLine {
    transition: transform $easeOutCubic $dur;
    transform: translateY(100%);
    visibility: hidden;

    .isPrev & {
        visibility: visible;
        transform: translateY(-100%);
        transition: transform $easeInQuad $dur;
    }

    .isActive & {
        visibility: visible;
        transform: translateY(0%);
        transition: transform $easeOutQuad $dur 0.57s;
    }
}

@include medium {
    .root {
        --headline-padding-bottom: 6rem;
        --column-width: 100%;
        --column-height: 100%;
        --column-top: 0;
    }

    .column {
        &:last-child {
            left: 0;
            top: 0;
            height: 100%;
            width: 100%;
        }
    }

    .textLockup {
        max-width: var(--block-max-width-plus-padding);
        padding-left: var(--block-padding-side);
    }

    .eyebrow,
    .headline {
        text-align: left;
    }

    .headline {
        margin-top: 1rem;
    }

    .imageWrapper {
        align-items: center;
    }
}
