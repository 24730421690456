@import 'styles/animations';
@import 'styles/breakpoints';

.gradientPanel {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;

    background-size: auto;
    background-repeat: repeat, no-repeat;
    background-blend-mode: overlay, soft-light, multiply;
    background-color: var(--slide-background-color);
    opacity: 0;
    will-change: opacity;
    transition: opacity $easeOutSine 1.17s;

    &.isActive {
        z-index: 2;
        opacity: 1;
    }
}
