@import 'styles/breakpoints';
@import 'styles/animations';

@keyframes animate-bar {
    0% {
        height: 0;
    }

    100% {
        height: 100%;
    }
}

.chartRow {
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    padding: var(--block-padding);
}

.innerChart {
    display: flex;
    width: 100%;
    max-width: var(--block-max-width);
    height: 100%;
    flex-direction: var(--innerChart-flex-direction, column);
    align-items: center;
    justify-content: center;
    background-color: var(--colors-white);
}

.chartInner {
    display: flex;
    width: 100%;
    min-height: var(--block-min-height, 37.5rem);
    flex-direction: row;
    align-items: flex-end;
    justify-content: center;
    padding: var(--chartInner-padding, var(--block-padding));
    background-color: var(--colors-blue);
    color: var(--colors-blue);
}

.barColumn {
    position: relative;
    display: flex;
    flex-direction: column-reverse;
}

.barWrapper {
    position: relative;
    display: flex;
    width: var(--barWrapper-width, 5.2rem);
    margin-right: var(--barWrapper-margin-right, 1rem);

    &.barWrapper1 {
        height: 12rem;
    }

    &.barWrapper2 {
        height: 16rem;
    }

    &.barWrapper3 {
        height: 24rem;
    }
}

.yearLabelWrapper {
    display: flex;
    width: var(--yearLabelWrapper-margin-right, 5.2rem);
    justify-content: center;
    margin-top: var(--yearLabelWrapper-margin-top, 0.7rem);
}

.yearLabel {
    color: var(--colors-cream);
}

.percentWrapper {
    position: absolute;
    top: 0;
    display: flex;
    width: var(--percentWrapper-margin-right, 5.2rem);
    justify-content: center;
    margin-bottom: var(--percentWrapper-margin-bottom, 0.7rem);
    transform: translateY(-40px);
}

.percentage {
    color: var(--colors-cream);
}

.chartBar {
    position: absolute;
    bottom: 0;
    width: 100%;
    background-color: var(--colors-orange);

    &.animate {
        animation-delay: calc(var(--animation-order) * 0.5s);
        animation-duration: 1s;
        animation-fill-mode: forwards;
        animation-name: animate-bar;
        animation-play-state: running;
        animation-timing-function: $easeOut;
    }
}

.lockup {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: var(--column-lastChild-padding, var(--block-padding-end));
    color: var(--colors-blue);
}

.headlineWrapper {
    display: flex;
    max-width: 40rem;
    justify-content: center;
}

.headline {
    max-width: 48rem;
    text-align: center;
}

.copyWrapper {
    max-width: 43rem;
    margin: var(--copyWrapper-margin, 2rem 0 0);
    text-align: center;
}

.iconWrapper {
    position: relative;
    display: flex;
    justify-content: center;
    padding: var(--iconWrapper-padding, 0 0 2rem 0);
}

.icon {
    position: relative;
    width: 6rem;
    height: 6rem;
    color: inherit;
    fill: inherit;
}

@include medium {
    .chartRow {
        --innerChart-flex-direction: row-reverse;
        --innerChart-padding: var(--block-padding-end) var(--block-padding-side);
        --chartInner-padding-left: 2.4rem;
        --iconWrapper-padding: 0 0 3rem 0;
        --copyWrapper-margin: 3rem 0 0 0;
        --barWrapper-width: 8.8rem;
        --yearLabelWrapper-margin-right: 8.8rem;
        --percentWrapper-margin-right: 8.8rem;
        --barWrapper-margin-right: 2rem;
        --block-min-height: 5rem;
        --chartInner-padding: 16.2rem 6.4rem;
        --yearLabelWrapper-margin-top: 1.2rem;
        --percentWrapper-margin-bottom: 1.2rem;
    }

    .icon {
        width: 8rem;
        height: 8rem;
    }
}
