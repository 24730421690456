@import 'styles/breakpoints';

.goalsBlock {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--colors-white);
    padding: var(--block-padding-end) 2.5rem;
    padding-bottom: 2rem;
    color: var(--colors-blue);
}

.innerWrapper {
    max-width: var(--block-max-width);
}

.headlineBlock {
    grid-column: 1 / -1;
    margin-bottom: 7.4rem;
}

.imgWrapper {
    display: flex;
    justify-content: center;
    padding-bottom: var(--imgWrapper-padding-bottom, 2.7rem);
}

.headlineWrapper {
    display: flex;
    justify-content: center;

    .headline {
        text-transform: uppercase;
        text-align: center;
        max-width: var(--headlineWrapper-max-width);
    }
}

.finePrint {
    text-align: center;
    font-style: italic;
    padding-top: var(--finePrint-padding-top);
    grid-column: 1 / -1;
}

@include medium {
    .goalsBlock {
        --imgWrapper-padding-bottom: 3.6rem;
        --finePrint-padding-top: 6rem;
        --headlineWrapper-max-width: 60rem;
    }
}
