@import 'styles/colors';
@import 'styles/animations';
@import 'styles/breakpoints';

$dur: 0.6s;

.root {
    position: absolute;
    top: 50%;
    left: 50%;
    width: var(--root-width, 75%);
    max-width: var(--root-max-width, 35rem);
    filter: drop-shadow(0 0.5rem 1.5rem rgba(0, 0, 0, 10%));
    transform: translate(-50%, -50%);
    transition: transform $easeOutQuad $dur;
    will-change: transform, z-index;

    &.top {
        z-index: 3;
        cursor: grab;
        transform: translate(-50%, -50%) rotate(-10deg);

        @media (hover: hover) {
            &:hover {
                transform: translate(-50%, -50%) rotate(-10deg) scale(1.05);
                transition: transform $bounce 0.3s;
            }

            &:active {
                cursor: grabbing;
            }
        }
    }

    &.middle {
        z-index: 2;
        transform: translate(-50%, -50%) rotate(5deg);
    }

    &.bottom {
        z-index: 1;
        transform: translate(-50%, -50%) rotate(-5deg);
    }

    // Top card going to bottom
    &.prev {
        animation-duration: $dur;
        animation-fill-mode: forwards;
        animation-name: swipe-off-right;
        animation-timing-function: $easeOutQuad;
        transition: none;
    }

    &.prev.isForward {
        animation-name: swipe-off-left;
    }
}

@keyframes swipe-off-right {
    // scale up
    0% {
        z-index: 4;
        opacity: 1;
        transform: translate(-50%, -50%) scale(1.1) rotate(-10deg);
    }

    // fade out and to the right
    60% {
        z-index: 4;
        opacity: 0;
        transform: translate(100%, -60%) scale(1.15) rotate(25deg);
    }

    // stay hidden, scale down, and move into deck
    75% {
        z-index: 1;
        opacity: 0;
        transform: translate(-50%, -50%) scale(0.8) rotate(0deg);
    }

    // fade and scale in from bottom of deck
    100% {
        z-index: 1;
        opacity: 1;
        transform: translate(-50%, -50%) scale(1) rotate(-5deg);
    }
}

@keyframes swipe-off-left {
    // scale up
    0% {
        z-index: 4;
        opacity: 1;
        transform: translate(-50%, -50%) scale(1.1) rotate(-10deg);
    }

    // fade out and to the right
    60% {
        z-index: 4;
        opacity: 0;
        transform: translate(-150%, -60%) scale(1.15) rotate(-45deg);
    }

    // stay hidden, scale down, and move into deck
    75% {
        z-index: 1;
        opacity: 0;
        transform: translate(-50%, -50%) scale(0.8) rotate(0deg);
    }

    // fade and scale in from bottom of deck
    100% {
        z-index: 1;
        opacity: 1;
        transform: translate(-50%, -50%) scale(1) rotate(-5deg);
    }
}

.imageInner {
    width: 100%;
    height: 100%;
}

.image {
    width: 100%;
    object-fit: cover;
}

@include medium {
    .root {
        --root-width: 47%;
        --root-max-width: 50rem;
    }
}

@include large {
    .root {
        --root-width: 60%;
        --root-max-width: 48rem;
    }
}

@include xxLarge {
    .root {
        --root-width: 69%;
    }
}
