@import 'styles/animations';
@import 'styles/breakpoints';

.root {
    position: relative;
    width: 100%;
    height: 182vw;
    min-height: 64rem;
    max-height: 108rem;
    display: flex;
    overflow: hidden;
    color: var(--colors-cream);

    &.isPlaying,
    &.isPaused,
    &.isStopped {
        // 🍆
    }
}

.bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.inner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    z-index: 3;
}

.column {
    position: absolute;
    right: 0;
    top: var(--column-top, 50%);
    width: var(--column-width, 100%);
    height: var(--column-height, 50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.ctaLockup {
    width: var(--ctaLockup-width, 100%);
    margin-top: 7.4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    text-transform: uppercase;
}

.buttonWrapper {
    margin: 3rem 0;
    position: relative;
    display: flex;
    justify-content: center;
}

.nav {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.navCount {
    width: 2.6rem;
    margin: 0 2.1rem 0 2.2rem;
}

.navItem {
    display: flex;
    align-items: center;
    justify-content: center;
}

.navItemAnimation {
    position: absolute;
    width: 3.2rem;
    height: 3.2rem;
    transform: rotate(-90deg);
    opacity: 0;
    transition: opacity 0.6s $easeOutCubic;

    .isContinuous & {
        opacity: 1;
    }

    .isPlaying & {
        opacity: 1;
    }

    .isSwitching & {
        opacity: 1;
        transform: rotate(90deg) scaleX(-1);
    }
}

.strokeCircle {
    stroke-dashoffset: 90;
    transition-property: stroke-dashoffset;
    transition-duration: 1s;

    .isPlaying & {
        transition-timing-function: linear;
        transition-duration: 1s;
        stroke-dashoffset: 0;
    }

    .isPaused & {
        opacity: 0.8;
    }

    .isSwitching & {
        transition-timing-function: $easeOutQuad;
        transition-duration: 1.1s;
    }
}

@include medium {
    .root {
        height: 140vw;
        max-height: none;
    }

    .ctaLockup {
        margin-top: 21.1rem;
    }
}

@include large {
    .root {
        --column-width: 50%;
        --column-height: 100%;
        --column-top: 0;

        min-height: 40rem;
        height: 50vw;
    }

    .column {
        transform: translateX(3%);
    }

    .ctaLockup {
        margin-top: 22.4rem;
    }

    .nav {
        position: absolute;
        top: 50%;
        left: -79%;
        transform: translate(-243%, -50%);
        flex-direction: column-reverse;
    }

    .navCount {
        margin: 2.2rem 0;
    }
}

@include xxLarge {
    .nav {
        transform: translate(-280%, -50%);
    }

    .buttonWrapper {
        margin: 4rem 0 0;
    }
}
