@import 'styles/breakpoints';
@import 'styles/animations';

@keyframes intro-in {
    0% {
        opacity: 0;
        transform: translateY(3%);
    }

    100% {
        opacity: 1;
        transform: translateY(0%);
    }
}

.categoryDetailIntro {
    position: relative;
    display: flex;
    justify-content: center;
}

.content {
    display: flex;
    width: 100%;
    height: calc(100vh - 14rem);
    min-height: 60rem;
    max-height: 120rem;
    flex-direction: column;
    align-items: center;
    padding: 2rem 0 0;

    > * {
        width: 100%;
        flex: 1 1 auto;
        flex-basis: 50%;
    }

    > picture {
        min-height: 0;
        flex-basis: 50%;
        padding: 0;
    }
}

.headlineWrap {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4rem 0 0;
    animation: intro-in $default $easeOutCubic 0.1s both;
    color: var(--colors-blue);

    span {
        line-height: 0.85 !important;
    }
}

.image {
    z-index: 1;
    width: 100%;
    height: 100%;
    animation: intro-in $default $easeOutCubic 0.3s both;
    object-fit: contain;
}

.iconWrapper {
    position: absolute;
    bottom: 2.4rem;
    left: 50%;
    transform: translate(-50%, 0);
}

.icon {
    --icon-visibility: visible;

    position: relative;
    width: 10px;
    height: 4.6rem;
    visibility: var(--icon-visibility);
}

@include medium {
    .content {
        max-width: 240rem; // felt right
        height: calc(100vmin - 100px);
        min-height: 45rem;
        max-height: 80rem;
        flex-direction: row;
        padding: 2rem 4rem 0;

        > * {
            width: 80%;
            height: 100%;
            flex-basis: 100%;
        }

        > picture {
            flex-basis: 100%;
        }
    }

    .headlineWrap {
        padding: 0 0 6vh;
    }

    .icon {
        --icon-visibility: hidden;
    }
}

@include large {
    .content {
        min-height: 56rem;
        max-height: 120rem;
    }
}
