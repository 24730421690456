@import 'styles/breakpoints';
@import 'styles/animations';

.root {
    width: 100%;
    max-width: var(--news-richText-max-width);
    margin: 2.4rem 0;
}

.player {
    display: flex;
    width: var(--player-width, 100%);
    height: 6rem;
    align-items: center;
    padding: 1.5rem 3rem;
    border-radius: 10rem;
    background-color: var(--colors-cream);
}

.audioButton {
    display: flex;
    width: 3rem;
    height: 3rem;
    align-items: center;
    justify-content: center;
    border: 0.15rem solid var(--colors-blue);
    border-radius: 50%;
}

.iconWrapper {
    &.pauseIcon {
        width: 1rem;
        height: 1.4rem;
    }

    &.playIcon {
        width: 1rem;
        height: 1rem;
        margin-bottom: 0.5rem;
        margin-left: 0.2rem;
    }
}

.progressBar {
    width: 100%;
}

.innerProgressBar {
    position: relative;
    margin: 0 1.6rem;
}

.playLine {
    position: absolute;
    z-index: 2;
    right: 0;
    left: 0;
    overflow: hidden;
    height: 0.1rem;
}

.innerPlayLine {
    width: 100%;
    height: 100%;
    background-color: var(--colors-blue);
    transform: translateX(-100%);
    transition: transform 0.25s $easeOutQuad;
}

.durationLine {
    position: absolute;
    z-index: 1;
    right: 0;
    left: 0;
    height: 0.1rem;
    background-color: #c4c4c4;
}

.duration {
    white-space: nowrap;
}

@include medium {
    .player {
        --player-width: 44rem;
    }
}
