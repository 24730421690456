@import 'styles/breakpoints';
@import 'styles/mixins';

.commitmentChart {
    height: 100%;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.contentWrapper {
    width: 100%;
    height: 100%;
    max-width: var(--block-max-width);
    display: flex;
    flex-direction: column;
    position: relative;
    color: var(--colors-blue);
    background-color: var(--colors-white);
}

.imgBlock {
    position: relative;
    top: var(--imgBlock-top, 1rem);
}

.innerWrapper {
    top: 25%;
    left: 0;
    position: var(--innerWrapper-position, relative);
    height: var(--innerWrapper-height, 50%);
    width: var(--innerWrapper-width, 100%);
    display: flex;
    z-index: 10;
    justify-content: center;
}

.lockup {
    width: 100%;
    align-items: center;
    max-width: var(--lockup-max-width);
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: var(--colors-blue);
    padding: var(--lockup-padding, 0 0 4.8rem);
    padding-left: var(--lockup-padding-left);
}

.headline {
    text-align: center;
    max-width: 48rem;
    text-transform: uppercase;

    &.standardsHeadline {
        text-transform: none;
    }
}

.copy {
    margin: var(--copy-margin, 2rem 0 0);
    text-align: center;
    order: 3;
}

.draggableChart {
    padding: 0;
    padding-top: var(--block-padding-end);

    --lockup-padding-top: var(--block-padding-end);
    --lockup-max-width: 60rem;
    --draggable-image-margin-top: 6rem;

    background-color: var(--colors-white);

    .imgBlock {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-top: var(--draggable-image-margin-top);

        picture {
            display: flex;
            justify-content: center;

            img {
                width: 180%;
                max-width: 100vmax;
                height: 100%;
            }
        }
    }

    .lockup {
        padding: 0 2.4rem;
        margin-bottom: -5rem;
    }
}

.chartRow {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: var(--block-padding);

    &.flushTop {
        padding-top: 0;
    }

    &.flushBottom {
        padding-bottom: 0;
    }
}

.innerChart {
    width: 100%;
    max-width: var(--block-max-width);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: var(--innerChart-flex-direction, column);
    padding: var(--innerChart-padding, 0 2.4rem);
    height: 100%;
}

.copyWrapper {
    max-width: 43rem;
    text-align: center;
    margin: var(--copyWrapper-margin, 2rem 0 0);
}

.chartInner {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--colors-yellow);
    color: var(--colors-blue);
    padding-left: var(--chartInner-padding-left);
    max-width: 51rem;
}

.chart {
    padding: var(--chart-padding, 4rem);
    display: flex;
    flex-direction: column;
    background-color: var(--colors-orange);

    @include rounded-corners;
}

.chartHeadline {
    text-transform: uppercase;
    margin-bottom: 1rem;
}

.standardsList {
    padding-top: var(--standardsList-padding-top, 2rem);
    width: 94%;
}

.standardWrapper {
    padding-bottom: var(--standard-padding-bottom, 1.2rem);

    &::after {
        display: block;
        content: '';
        border-top: 0.1rem solid var(--colors-blue);
        width: 100%;
        margin-top: var(--standardWrapper-after-margin-top, 1.2rem);
    }
}

.standardIconWrapper {
    position: absolute;
    display: flex;
    justify-content: center;
    transform: var(
        --standardIcon-transform,
        translateX(-5rem) translateY(-1rem)
    );
}

.standardIcon {
    position: relative;
    width: var(--standardIcon-width, 3.6rem);
    height: var(--standardIcon-height, 3.6rem);
    fill: inherit;
    color: inherit;
}

@include medium {
    .commitmentChart {
        --innerWrapper-height: 100%;
        --innerWrapper-width: 50%;
        --lockup-padding-top: 0;
        --copy-margin: 3rem 0 0 0;
        --lockup-padding: 0;
        --imgBlock-top: 5rem;
        --innerWrapper-position: absolute;
    }

    .draggableChart {
        --innerWrapper-position: relative;
        --imgBlock-top: 0;
        --lockup-padding-top: var(--block-padding-end);
        --innerWrapper-width: 100%;
        --draggable-image-margin-top: 11rem;

        .imgBlock {
            picture {
                display: block;

                img {
                    max-width: 100%;
                }
            }
        }

        .lockup {
            margin-bottom: -6rem;
        }
    }
}

@include large {
    .chartRow {
        --column-width: 50%;
        --innerChart-flex-direction: row;
        --copyWrapper-margin: 3rem 0 0;
        --standardsList-padding-top: 3rem;
        --standardWrapper-after-margin-top: 2rem;
        --standard-padding-bottom: 2rem;
        --lockup2-padding: var(--block-padding);
        --lockup2-padding-left: 0;
        --chart-padding: 6rem;
        --standardIcon-width: 5rem;
        --standardIcon-height: 5rem;
        --standardIcon-transform: translateX(-8rem) translateY(-1rem);
        --copy-margin: 3rem 0 0;
        --chartInner-padding-left: 2.4rem;
    }

    .chartInner {
        max-width: none;
        width: calc(100% + 7rem);
    }

    .lockup {
        padding-right: 0;
    }

    .copyWrapper {
        max-width: 35rem;
    }
}

@include xLarge {
    .copyWrapper {
        max-width: 43rem;
    }
}

@include xxLarge {
    .chartRow {
        --innerChart-padding: 0 var(--block-padding-end);
    }

    .chartInner {
        max-width: 51rem;
    }

    .lockup {
        padding-right: 9.6rem;
    }
}
