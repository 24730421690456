@import 'styles/breakpoints';

.root {
    text-align: center;
    padding: var(
        --root-padding,
        var(--block-padding-end) var(--block-padding-side)
    );
    color: var(--colors-blue);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    max-width: var(--block-max-width);
    min-height: calc(100vh - var(--header-height));
    margin: 0 auto;
}

.icon {
    width: var(--icon-width, 6rem);
    height: var(--icon-height, 5.25rem);
    margin-bottom: 2rem;
}

.richText {
    padding-top: var(--richText-padding-top, 1rem);
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 80%;
}

.headline {
    text-transform: uppercase;
    margin-bottom: var(--headline-margin-bottom, 2.4rem);
    max-width: var(--headline-max-width, 90%);
}

@include medium {
    .root {
        --root-padding: 4.8rem var(--block-padding-side);
        --icon-width: 8rem;
        --icon-height: 7rem;
        --headline-max-width: none;
    }
}
