@import 'styles/breakpoints';

.blockAnchorProductList {
    position: relative;
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    color: var(--colors-blue);
    padding: var(--block-padding-end) 0 2.7rem 0;
}

.introLockup {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--colors-white);
    padding: 4.2rem 2.4rem;
    border-radius: 0.8rem;
    width: 100%;
}

.textLockup {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 28rem;
}

.headline {
    position: relative;
    text-transform: uppercase;
    width: 100%;
    text-align: center;
    margin: 0 0 2rem 0;
}

.copy {
    text-align: center;
}

.richText {
    width: 100%;
}

.paragraph {
    position: relative;
    width: 100%;
    margin: 3.2rem 0 0rem;

    &:after {
        content: '';
        position: absolute;
        width: 100%;
        left: 0;
        height: 0;
        bottom: -1.6rem;
        border-bottom: 0.1rem solid rgba(0, 30, 96, 0.2);
    }
}

.heading3 {
    margin: 6.2rem 0 -1.3rem 0;

    &:first-child {
        margin-top: 4rem;
    }
}

.heading3Alt {
    margin: 2.1rem 0 0;

    &:first-child {
        margin-top: 4rem;
    }
}

.heading4 {
    text-transform: uppercase;
    margin: 5rem 0 -1.6rem 0;
}

@include medium {
    .textLockup {
        max-width: 58rem;
    }
}

@include large {
    .blockAnchorProductList {
        align-items: flex-start;
        padding: var(--block-padding-end) 0 2.7rem 0;
    }

    .headline {
        margin: 0 0 4rem 0;
        text-align: left;
    }

    .copy {
        text-align: left;
        margin: 0 0 2.4rem 0;
    }

    .introLockup {
        flex-direction: row;
        padding: 4.8rem var(--column-width);
        justify-content: space-between;

        > picture {
            width: 50%;
            height: 100%;
        }
    }

    .textLockup {
        align-items: flex-start;
        max-width: 36rem;
        width: 50%;
    }

    .richText {
        padding-left: 6rem;
        padding: 4.8rem var(--column-width);
    }

    .paragraph {
        margin: 3.8rem 0 0rem;

        &:after {
            bottom: -1.9rem;
        }
    }

    .heading3 {
        margin: 12rem 0 -1.3rem 0;
    }

    .heading4 {
        margin: 5.7rem 0 -1.3rem 0;
    }
}

@include xxLarge {
    .textLockup {
        max-width: 40rem;
        width: 100%;
    }
}
