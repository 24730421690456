@import 'styles/breakpoints';
@import 'styles/mixins';

.blockCardTrioInline {
    position: relative;
    width: 100%;
    padding: var(--block-padding);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &.flushTop {
        padding-top: 0;
    }

    &.flushBottom {
        padding-bottom: 0;
    }
}

.inner {
    width: 100%;
    max-width: var(--block-max-width);
}

.cards {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.listItemGap {
    height: 2rem;
}

.listItem {
    @include rounded-corners($overflow: hidden);
}

@include large {
    .cards {
        display: flex;
    }

    .listItemGap {
        height: auto;
        width: 1.2rem;
    }

    .listItem {
        width: 33.3333%;
        margin: 0;

        &:last-child {
            padding-right: 0;
        }
    }
}
