@import 'styles/breakpoints';
@import 'styles/mixins';

.blockImageFlex {
    position: relative;
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: var(--block-padding);
    background-color: var(--background-color);

    &.embeddedEntry {
        width: calc(100% + 2.4rem);
        padding: 4.8rem 0;
    }

    &.flushTop {
        padding-top: 0;
    }

    &.flushBottom {
        padding-bottom: 0;
    }

    &.fullWidth {
        padding-right: 0;
        padding-left: 0;
    }
}

.blockImage {
    position: relative;
    width: 100%;
    max-width: var(--block-max-width);
}

.image {
    width: 100%;
    max-width: none;

    .roundedTop & {
        @include rounded-corners($overflow: hidden, $radius: 2rem 2rem 0 0);
    }

    .roundedBottom & {
        @include rounded-corners($overflow: hidden, $radius: 0 0 2rem 2rem);
    }

    .roundedTop.roundedBottom & {
        @include rounded-corners($overflow: hidden);
    }
}

@include medium {
    .blockImageFlex {
        &.embeddedEntry {
            width: 100%;
        }
    }

    .fullWidth {
        .blockImage {
            max-width: none;
        }
    }
}
