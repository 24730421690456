@import 'styles/breakpoints';

.textLockup {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.lockup {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.eyebrow {
    text-transform: uppercase;
}

.headline {
    max-width: 48rem;
    margin: var(--copy-headline, 1.5rem 0 0);
    text-align: center;
    text-transform: uppercase;

    span {
        display: block;
    }

    .hasRichHeadline & {
        max-width: none;
    }
}

.copy {
    max-width: 33rem;
    margin: var(--copy-margin, 2rem 0 0);
    text-align: center;
}

.buttonWrapper {
    position: relative;
    margin-top: var(--buttonWrapper-margin-top, 3.6rem);
}

@include medium {
    .blockTwoColumn {
        --column-width: 50%;
        --buttonWrapper-margin-top: 4.8rem;
        --buttonWrapper-width: none;
        --copy-margin: 3rem 0 0;
    }
}

@include large {
    .headline {
        --copy-headline: 2.5rem 0 0;
    }

    .copy {
        max-width: 42.3rem;
    }
}
