@import 'styles/colors';
@import 'styles/animations';
@import 'styles/breakpoints';

$dur: 0.5s;

.description {
    position: absolute;
    z-index: 10;
    top: 50%;
    right: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: var(--description-padding, 0 2rem);
    opacity: 1;
    pointer-events: auto;
    text-align: center;
    transform: translate(0, -50%);
    transition: opacity $easeOutCubic $dur;
}

.icon {
    width: 9rem;
    margin: 2rem 0;
}

.eyebrow {
    margin-bottom: var(--eyebrow-margin-bottom, 1.2rem);
    text-transform: uppercase;

    p {
        margin: 0;
    }
}

.headline {
    margin-bottom: var(--headline-margin-bottom, 1.4rem);
    text-transform: uppercase;
}

.bodyCopy {
    max-width: var(--body-copy-max-width, none);
    max-width: 34.8rem;
    margin-bottom: var(--body-copy-margin-bottom, 2rem);

    p {
        margin: 0;
    }
}

@include medium {
    .description {
        --body-copy-max-width: 60%;
    }
}

@include large {
    .description {
        --eyebrow-margin-bottom: 2.4rem;
        --headline-margin-bottom: 3rem;
        --body-copy-margin-bottom: 3.2rem;
        --body-copy-max-width: 37rem;
        --description-padding: var(--block-padding-end) 4.6rem
            var(--block-padding-end) var(--block-padding-side);

        margin-top: 0;
    }

    .icon {
        margin: 0 0 3rem;
    }
}

@include xxLarge {
    .description {
        --description-padding: 9.6rem 4.8rem 9.6rem var(--block-padding-side);
    }
}
