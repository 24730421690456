@import 'styles/breakpoints';

.root {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    color: var(--colors-blue);

    &.background-white {
        background-color: var(--colors-white);
    }
}

.headline {
    padding-bottom: 1rem;
}

.inner {
    width: 100%;
    max-width: var(--block-max-width);
    padding: var(--block-padding-end);

    .flushTop & {
        padding-top: 0;
    }

    .flushBottom & {
        padding-bottom: 0;
    }
}

.resources {
    display: flex;
    flex-direction: var(--resources-flex-direction, column);
    justify-content: var(--resources-justify-content, center);
    padding: 0;
    margin: var(--resources-margin, 2rem 0);
    list-style: none;

    &:has(.resourceIconBox) {
        --resources-margin: 3rem 0 0;
    }
}

.resourceTitle {
    margin-bottom: 5.2rem;
    text-align: center;
}

.resource {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: var(--resource-margin, 0 0 6rem);
}

.resourceIconBox {
    display: flex;
    width: 8.4rem;
    height: 8.4rem;
    align-items: center;
    align-self: center;
    justify-content: center;
    margin-bottom: 1rem;
}

.resourceIconWrapper {
    position: relative;

    &.branding {
        width: 6.8rem;
        height: 5rem;
    }

    &.info {
        width: 7.7rem;
        height: 6.7rem;
    }

    &.tips {
        width: 6rem;
        height: 6rem;
    }
}

.downloadList {
    width: 100%;
    max-width: 60rem;
    padding: 0;
    margin: 0;
    list-style: none;
}

.download {
    &::after {
        display: block;
        width: 100%;
        height: 0.1rem;
        background-color: var(--colors-blue);
        content: '';
        opacity: 0.2;
    }
}

.innerDownload {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 0;
    text-decoration: none;
}

.downloadIconWrapper {
    position: relative;
    width: 2.8rem;
    height: 2.8rem;
    flex-shrink: 0;
}

.downloadTitle {
    text-transform: uppercase;
}

@include large {
    .inner {
        padding: var(--block-padding);
    }

    .headline {
        padding-bottom: 5rem;
    }

    .resources {
        --resources-flex-direction: row;
        --resources-justify-content: space-between;

        &:has(.resourceIconBox) {
            --resources-margin: 6rem 0 0;
        }
    }

    .resource {
        width: 33%;
        flex: 1;
        padding: 0 6.4rem 6rem;
        border-right: 1px solid rgba(0, 30, 96, 20%);

        --resource-margin: 0;

        &:first-child {
            padding-left: 0;
        }

        &:last-child {
            padding-right: 0;
            border: none;
        }
    }

    .innerDownload {
        padding: 2rem 0;
    }
}
