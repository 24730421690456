@import 'styles/animations';
@import 'styles/breakpoints';
@import 'styles/colors';

.root {
    position: relative;
    display: flex;
    overflow: hidden;
    width: 100%;
    height: min(180vh, 100rem);
    min-height: var(--root-min-height, none);
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: var(--root-padding, 0);
    color: var(--colors-blue);
}

.inner {
    display: flex;
    width: 100%;
    max-width: var(--block-max-width-plus-padding);
    height: 100%;
    flex-direction: var(--inner-flex-direction, column);
}

.col {
    position: relative;
    flex: 1;
}

.mediaWrapper {
    position: relative;
    height: 100%;
    cursor: pointer;
}

.nav {
    position: absolute;
    bottom: -2rem;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.navArrow {
    margin: 1.5rem;
}

.dots {
    position: relative;
    display: flex;
    height: 6rem;
    align-items: center;
    justify-content: center;
    pointer-events: none;
    user-select: none;
}

.dot {
    width: 1rem;
    height: 1rem;
    border: 0.2rem solid var(--colors-blue);
    border-radius: 50%;
    margin: 0.4rem;
    background-color: transparent;
    transition: background-color $easeOutCubic $superSlow;

    &.active {
        background-color: var(--colors-blue);
    }
}

@include smallOnly {
    .root {
        --root-min-height: 85rem;
    }
}

@include medium {
    .root {
        height: 150vw;
        max-height: none;

        .col {
            flex: 1 1 60%;

            &:last-child {
                flex: 1 1 40%;
            }
        }
    }

    .nav {
        bottom: 1rem;
    }
}

@include large {
    .root {
        --root-padding: 5rem 0 7rem;
        --inner-flex-direction: row-reverse;

        height: 56.25vw;
        min-height: 45rem;
        max-height: 90rem;

        .col {
            flex: 1;

            &:last-child {
                flex: 1;
            }
        }
    }

    .nav {
        bottom: -1rem;
    }
}

@include xxLarge {
    .root {
        --root-padding: var(--block-padding-end) 0;
    }

    .col {
        &:first-child {
            max-height: 65rem;
        }
    }

    .nav {
        bottom: -5rem;
    }
}
