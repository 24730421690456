@import 'styles/breakpoints';
@import 'styles/mixins';

.root {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    padding: var(--block-padding);
    max-width: var(--block-max-width-plus-padding);
}

.inner {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: var(--landingTwoColumn-flex-direction, column);

    @include rounded-corners($overflow: hidden);

    .flushTop & {
        padding-top: 0;
    }
}

.column {
    position: relative;
    width: var(--column-width, 100%);

    &:last-child {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: var(--column-lastChild-padding, var(--block-padding-end));
        background-color: var(--colors-white);
        color: var(--colors-blue);

        .holidayBlue & {
            color: var(--colors-cream);
            background-color: var(--colors-holidayBlue);
        }

        .holidayRust & {
            color: var(--colors-cream);
            background-color: var(--colors-holidayRust);
        }

        .holidaySquash & {
            color: var(--colors-cream);
            background-color: var(--colors-holidaySquash);
        }

        .tillamookBlue {
            color: var(--colors-cream);
            background-color: var(--colors-blue);
        }
    }
}

.lockup {
    max-width: var(--lockup-max-width, 33rem);

    .hasRichHeadline & {
        max-width: none;
    }
}

@include large {
    .root {
        --landingTwoColumn-flex-direction: row-reverse;
        --column-width: 50%;
        --column-lastChild-padding: 0;
        --lockup-max-width: 36.6rem;

        &.reversed {
            --landingTwoColumn-flex-direction: row;
        }
    }

    .column {
        &:last-child {
            padding-bottom: 50%;
            height: 0;
        }
    }

    .columnInner {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
}

@include xLarge {
    .root {
        --lockup-max-width: 45.6rem;
    }
}
