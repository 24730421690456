@import 'styles/animations';
@import 'styles/breakpoints';

.blockCardSwipe {
    position: relative;
    display: flex;
    overflow: hidden;
    width: 100%;
    height: 182vw;
    min-height: 64rem;
    max-height: 108rem;
}

.image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.inner {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.cards {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    opacity: 0;

    .isInView & {
        opacity: 1;
        transition: opacity $default $easeOutQuad 3s;
    }
}

.cover {
    position: relative;
    width: 74%;
    transition: opacity $default $easeOutQuad 2.4s;

    span {
        width: 100%;
        height: 100%;
    }

    &.ourCulture {
        color: var(--colors-blue);
    }

    .isInView & {
        opacity: 0;
    }
}

.iconContainer {
    position: relative;
    width: 74%;
    transition: opacity $default $easeOutQuad 2.4s;

    .customIcon {
        width: 100%;
        object-fit: contain;
    }

    .isInView & {
        opacity: 0;
    }
}

.nav {
    position: absolute;
    bottom: var(--block-padding-end);
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--colors-cream);

    .isShifting & {
        pointer-events: none;
    }
}

.navCount {
    width: 2.6rem;
    margin: 0 2.1rem 0 2.2rem;
}

.navItem {
    display: flex;
    align-items: center;
    justify-content: center;
}

@include medium {
    .blockCardSwipe {
        height: 100vw;
    }

    .cover {
        width: 50%;
        max-width: 56.4rem;
        max-height: 32rem;
    }
}

@include large {
    .blockCardSwipe {
        height: 50vw;
    }

    .cover {
        width: 45%;
        margin-top: -1%;
    }

    .iconContainer {
        width: 45%;
    }

    .nav {
        top: 50%;
        left: var(--block-padding-end);
        flex-direction: column-reverse;
        transform: translateY(-50%);
    }

    .navCount {
        margin: 2.2rem 0;
    }
}

@include xxLarge {
    .cover {
        margin-top: -6.2%;
    }
}

@include xxxLarge {
    .cover {
        margin-top: -10%;
    }
}
