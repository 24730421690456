@import 'styles/animations';
@import 'styles/breakpoints';

.root {
    position: relative;
    z-index: 0;
    display: flex;
    overflow: hidden;
    width: 100%;
    height: calc(100vh - 10rem);
    min-height: 40rem;
    color: var(--colors-cream);

    @media (min-height: 620px) {
        --aspect-height: calc(100vh - 16rem);
    }
}

.bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.inner {
    position: absolute;
    z-index: 3;
    top: 0;
    left: 0;
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.column {
    position: absolute;
    z-index: 2;
    top: var(--column-top, 50%);
    left: 0;
    display: flex;
    width: var(--column-width, 100%);
    height: var(--column-height, 50%);
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &:last-child {
        transform: translateY(-4rem);
    }
}

.ctaLockup {
    z-index: 2;
    display: flex;
    width: var(--ctaLockup-width, 100%);
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 0;
    text-align: center;
    text-transform: uppercase;
}

.iconWrapper {
    position: relative;
    display: flex;
    justify-content: center;
    margin-top: var(--iconWrapper-margin-top, 2rem);

    .icon {
        position: relative;
        width: var(--icon-width, 4.8rem);
        height: var(--icon-height, 1.1rem);
    }
}

.buttonWrapper {
    position: relative;
    display: flex;
    justify-content: center;
    margin: 5rem 0;
}

.nav {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    .isShifting & {
        pointer-events: none;
    }
}

.navCount {
    width: 2.6rem;
    margin: 0 2.1rem 0 2.2rem;
    color: var(--colors-cream);
}

.navItem {
    display: flex;
    align-items: center;
    justify-content: center;
}

.navItemAnimation {
    position: absolute;
    width: 3.1rem;
    height: 3.1rem;
    opacity: 0;
    transform: rotate(-90deg);
    transition: opacity 0.6s $easeOutCubic;

    .isContinuous & {
        opacity: 1;
    }

    .isPlaying & {
        opacity: 1;
    }

    .isSwitching & {
        opacity: 1;
        transform: rotate(90deg) scaleX(-1);
    }
}

.strokeCircle {
    stroke-dashoffset: 90;
    transition-duration: 1s;
    transition-property: stroke-dashoffset;

    .isPlaying & {
        stroke-dashoffset: 0;
        transition-duration: 1s;
        transition-timing-function: linear;
    }

    .isPaused & {
        opacity: 0.8;
    }

    .isSwitching & {
        transition-duration: 1.1s;
        transition-timing-function: $easeOutQuad;
    }
}

@include medium {
    .root {
        --iconWrapper-margin-top: 2.8rem;
        --icon-width: 6rem;
        --icon-height: 1.3rem;
        --column-width: 100%;
        --column-height: 100%;
        --column-top: 0;

        height: calc(100vh - 6rem);
        min-height: 60rem;
    }

    .ctaLockup {
        position: relative;
        max-width: var(--block-max-width-plus-padding);
        align-items: flex-start;
        padding-left: var(--block-padding-side);
        margin-top: 19.8rem;
    }

    .nav {
        position: absolute;
        top: 50%;
        right: var(--block-padding-side);
        flex-direction: column-reverse;
        transform: translate(0, -50%) translateY(-10rem);
    }

    .navCount {
        margin: 2.2rem 0;
    }
}

@include large {
    .root {
        height: calc(100vh - 6rem);
    }
}

@include xxLarge {
    .ctaLockup {
        margin-top: 25rem;
    }
}
