@import 'styles/mixins';
@import 'styles/breakpoints';

.root {
    --dimension: 3.4rem;
    --icon-dimension: 0.9rem;

    position: relative;
    width: var(--dimension);
    height: var(--dimension);
    background-color: var(--hotspot-color);
    border-radius: 50%;

    @media (hover: hover) {
        &:hover {
            transform: scaleX(1.1) scaleY(1.1);
        }
    }
}

.button {
    position: relative;
    width: 100%;
    height: 100%;
}

.highlight {
    position: absolute;
    z-index: 1;

    top: 0;
    left: 0.45rem;
    width: 2rem;
    height: 2rem;
    transition: transform 0.5s;
}

.icon {
    width: var(--icon-dimension);
    height: var(--icon-dimension);
    color: var(--colors-cream);
}

@include large {
    .root {
        --dimension: 4.2rem;
        --icon-dimension: 1.2rem;
    }

    .highlight {
        top: 0.2rem;
        left: 0.6rem;
    }
}
