@import 'styles/animations';
@import 'styles/breakpoints';
@import 'styles/colors';
@import 'styles/mixins';

$dur: 0.6s;

.cardSwipe {
    position: absolute;
    z-index: 0;
    top: 50%;
    left: 50%;
    display: flex;
    width: 80vw;
    max-width: 40rem;
    height: 80vw;
    max-height: 40rem;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    animation-duration: $superSlow;
    animation-fill-mode: forwards;
    animation-timing-function: $easeOutQuad;
    background-color: rgba(color('cream'), 0.95);
    color: var(--colors-blue);
    opacity: 0;
    pointer-events: none;
    text-align: center;
    transform: translate(-50%, -50%);

    @include rounded-corners;

    &.isPrev {
        z-index: 1;
        animation-name: swipe-next;

        &.dirNext {
            animation-name: swipe-prev;
        }
    }

    &.isActive {
        animation-delay: 0.1s;
        animation-duration: $default;
        animation-name: set-active;
        animation-timing-function: $bounce;
        pointer-events: auto;
    }
}

.inner {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 2.4rem;
    text-align: center;
    user-select: none;
}

.eyebrow {
    margin-bottom: 2rem;
    text-transform: uppercase;
}

.icon {
    position: relative;

    &.treeRule {
        span {
            width: 16.9rem;
            height: 3.2rem;
        }
    }

    &.anchorRule {
        span {
            width: 16.2rem;
            height: 2.8rem;
        }
    }

    &.cow {
        span {
            width: 5.9rem;
            height: 4.3rem;
        }
    }

    &.loaf {
        span {
            width: 5.6rem;
            height: 2.6rem;
        }

        margin-top: 2rem;
    }

    &.people {
        span {
            width: 6.8rem;
            height: 5.7rem;
        }
    }

    &.trophy {
        span {
            width: 5.4rem;
            height: 4.9rem;
        }
    }

    &.heart {
        span {
            width: 5rem;
            height: 4.5rem;
        }
    }
}

.iconContainer {
    position: relative;
    width: var(--icon-container-width, 6rem);
    height: var(--icon-container-height, 6rem);
}

.swiper {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

@keyframes set-active {
    from {
        z-index: 1;
        opacity: 0;
        transform: translate(-50%, -50%) scale(0.92);
    }

    to {
        z-index: 2;
        opacity: 1;
        transform: translate(-50%, -50%) scale(1);
    }
}

@keyframes swipe-prev {
    from {
        z-index: 2;
        opacity: 1;
        transform: translate(-50%, -50%);
    }

    to {
        z-index: 1;
        opacity: 0;
        transform: translate(-100%, -50%) translate(-150%, -65%) rotate(65deg);
    }
}

@keyframes swipe-next {
    from {
        z-index: 2;
        opacity: 1;
        transform: translate(-50%, -50%);
    }

    to {
        z-index: 1;
        opacity: 0;
        transform: translate(50%, -50%) translate(150%, -65%) rotate(-65deg);
    }
}

@include medium {
    .cardSwipe {
        width: 67.4rem;
        max-width: none;
        height: 41.3rem;
        max-height: none;
        animation-duration: 0.5s;
    }

    .inner {
        padding: 0 8rem;

        p {
            margin: 0 0 2.4rem;
        }
    }

    .icon {
        &.treeRule {
            span {
                width: 30.2rem;
                height: 5.2rem;
            }
        }

        &.anchorRule {
            span {
                width: 31.2rem;
                height: 5.3rem;
            }
        }

        &.cow {
            span {
                width: 9.4rem;
                height: 9.6rem;
            }
        }

        &.loaf {
            span {
                width: 9.2rem;
                height: 4.5rem;
            }
        }

        &.people {
            span {
                width: 10.9rem;
                height: 8.7rem;
            }
        }

        &.trophy {
            span {
                width: 8.9rem;
                height: 7.6rem;
            }
        }

        &.heart {
            span {
                width: 7.8rem;
                height: 7rem;
            }
        }
    }

    @keyframes set-active {
        from {
            z-index: 1;
            opacity: 0;
            transform: translate(-50%, -50%) scale(0.85);
        }

        to {
            z-index: 2;
            opacity: 1;
            transform: translate(-50%, -50%) scale(1);
        }
    }

    @keyframes swipe-prev {
        from {
            z-index: 2;
            opacity: 1;
            transform: translate(-50%, -50%);
        }

        to {
            z-index: 1;
            opacity: 0;
            transform: translate(-100%, -50%) translate(-50%, -75%)
                rotate(45deg);
        }
    }

    @keyframes swipe-next {
        from {
            z-index: 2;
            opacity: 1;
            transform: translate(-50%, -50%);
        }

        to {
            z-index: 1;
            opacity: 0;
            transform: translate(50%, -50%) translate(50%, -75%) rotate(-45deg);
        }
    }
}

@include large {
    .cardSwipe {
        animation-duration: $slow;

        --icon-container-width: 8rem;
        --icon-container-height: 8rem;
    }
}
