@import 'styles/breakpoints';
@import 'styles/animations';
@import 'styles/mixins';

.root {
    --close-dimension: 4rem;
    --close-icon-dimension: 1.8rem;
    --image-aspect: 1.4;
    --inner-padding: 4.8rem 2.4rem;
    --content-gap: 3.6rem;

    position: absolute;
    z-index: 10;
    top: calc(50% - 1rem);
    left: 50%;
    width: calc(100% - 4.8rem);
    max-height: calc(100% - var(--header-height) - var(--header-subnav-height));
    border-radius: 2rem;
    background-color: var(--colors-white);
    box-shadow: 0 4.2rem 9.6rem rgba(0, 0, 0, 8%),
        0 1.3rem 3rem rgba(0, 0, 0, 4%), 0 0.55rem 1.53rem rgba(0, 0, 0, 3%),
        0 0.187rem 0.714rem rgba(0, 0, 0, 2%);
    color: var(--colors-blue);
    opacity: 0;
    overflow-x: hidden;
    pointer-events: none;
    transform: translate3d(-50%, -45%, 0);

    &.isOpen {
        opacity: 1;
        pointer-events: auto;
        transform: translate3d(-50%, -50%, 0);
        transition: opacity $slow $easeOutQuad $default,
            transform $slow $bounce $default;
    }

    &.isClosing {
        transition: opacity $default $easeOutQuad, transform $default $bounce;
    }

    &.theme-shreds {
        background-color: var(--colors-cream);

        --content-gap: 2rem;
    }
}

.inner {
    padding: var(--inner-padding);
}

.contentWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: var(--content-gap);
}

.eyebrow {
    margin-bottom: 1.2rem;
    text-transform: uppercase;
}

.headline {
    max-width: 50rem;
    margin-bottom: 1.6rem;
    text-transform: uppercase;

    .theme-cream-cheese & {
        margin-bottom: 1.2rem;
    }

    .theme-holiday & {
        font-size: min(3.6rem, 8vw);
    }
}

.copy {
    margin-bottom: 0;
}

@media (max-width: 375px) {
    .headline {
        font-size: clamp(2.4rem, -0rem + 8vw, 3.6rem);
    }

    .copy {
        font-size: clamp(1.2rem, 0rem + 4.8vw, 1.8rem);
    }
}

.closeButton {
    position: absolute;
    top: 1rem;
    right: 0.8rem;
    width: var(--close-dimension);
    height: var(--close-dimension);
}

.closeIcon {
    width: var(--close-icon-dimension);
    height: var(--close-icon-dimension);
}

.imageWrapper {
    position: relative;
    overflow: hidden;
    width: 100%;
    border-radius: 1rem;
    aspect-ratio: var(--image-aspect);

    &.image-square {
        --image-aspect: 2;

        border-radius: none;
        margin-top: 1.6rem;

        picture:nth-child(2) .image {
            left: unset;
        }
    }
}

.image {
    width: 100%;
    height: 100%;
    object-fit: cover;

    @include spread;

    .image-square & {
        width: 47%;
        border-radius: 1rem;
    }
}

.cta {
    gap: 1rem;
    text-transform: uppercase;

    @include flex-center;

    .theme-shreds & {
        justify-content: flex-start;
    }
}

.chevron {
    width: 0.8rem;
    height: 1.2rem;
}

@include medium {
    .root {
        --image-aspect: 2.1;
        --inner-padding: 4.8rem 3.6rem 3.6rem;

        left: 0;
        width: 50%;
        max-width: 70rem;
        margin-left: var(--block-padding-side);
        transform: translate3d(0%, -45%, 0);

        &.isOpen {
            transform: translate3d(-0%, -50%, 0);
        }

        &.theme-shreds {
            width: calc(100% - 12rem);
            max-width: none;
        }
    }

    .cta {
        justify-content: flex-start;
    }
}

@include large {
    .root {
        --close-dimension: 5rem;
        --close-icon-dimension: 2.2rem;
        --content-gap: 4rem;

        min-width: 50rem;

        &.theme-shreds {
            width: calc(50% - 6rem);
            max-width: 70rem;
            margin-left: 5rem;
        }
    }
}

@include xLarge {
    .root {
        --inner-padding: 4.8rem;
        --content-gap: 4.8rem;

        width: 43%;
    }

    .headline {
        .theme-cream-cheese,
        .theme-holiday & {
            font-size: 4.8rem;
        }
    }
}

@include xxLarge {
    .root {
        margin: 0 var(--block-padding-side);
    }
}
