@import 'styles/breakpoints';

.root {
    position: relative;
    width: 100%;
    color: var(--colors-blue);
}

.inner {
    position: relative;
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.column {
    position: relative;
    width: 100%;
}

.eyebrow {
    margin-bottom: 1.6rem;
    text-transform: uppercase;
}

.svgLockup {
    position: relative;

    --width: 64vw;
    --min-width: 30rem;

    width: var(--width);
    min-width: var(--min-width);
    height: calc(var(--svg-ratio) * var(--width));
    min-height: calc(var(--svg-ratio) * var(--min-width));
    padding: 0;
    margin-top: -1rem;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

.iconPlacement {
    position: absolute;
    z-index: 10;

    // factor in header padding
    top: 0;
    right: var(--icon-position, 4rem);
}

.icon {
    position: relative;
    width: 6rem;
}

.aspect {
    width: 100%;
    height: 0;
    padding-bottom: 100%;

    .hasDescription & {
        padding-bottom: 130%;
    }

    h1 {
        margin-bottom: 1.6rem;
    }
}

.aspectInner {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.influencerImage {
    position: relative;
    width: 8rem;
    height: 8rem;
    margin-bottom: 1.6rem;

    .image {
        border-radius: 50%;
    }
}

.image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: var(--vertical-alignment);
}

.ctaWrapper {
    margin-top: 3.2rem;
}

.description {
    display: block;
    padding-right: 2.4rem;
    padding-left: 2.4rem;
    margin-top: 3.2rem;
    margin-bottom: 0;
    text-align: center;
}

@include smallOnly {
    .aspectInner {
        h1 {
            font-size: min(4.8rem, 10vw);

            .hasInfluencerImage & {
                font-size: 3.6rem;
            }
        }
    }
}

@include medium {
    .influencerImage {
        width: 12rem;
        height: 12rem;
        margin-bottom: 2.4rem;
    }

    .aspect {
        h1 {
            margin-bottom: 2.4rem;
        }
    }
}

@include large {
    .root {
        height: calc(100vh - var(--header-height));
        min-height: 50vw;
    }

    .icon {
        width: 8rem;
    }

    .iconPlacement {
        top: 0;

        --icon-position: 9.6rem;

        .imageOnLeft & {
            right: unset;
            left: var(--icon-position, 4rem);
        }
    }

    .inner {
        height: 100%;
        flex-direction: row;

        .imageOnLeft & {
            flex-direction: row-reverse;
        }
    }

    .column {
        position: relative;
        width: 50%;
        height: 100%;
    }

    .aspect {
        height: 100%;
        padding-bottom: 0;

        .hasDescription & {
            padding-bottom: 0;
        }
    }

    .svgLockup {
        --width: 36vw;
        --min-width: 29rem;

        margin-top: -2rem;
    }

    .ctaWrapper {
        margin-top: 4.8rem;
    }

    .description {
        max-width: min(72%, 60rem);
        padding-right: 0;
        padding-left: 0;
    }
}

@include xLarge {
    .aspectInner {
        padding: 0 4.8rem;
    }
}
