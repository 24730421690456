@import 'styles/animations';
@import 'styles/breakpoints';
@import 'styles/mixins';

$dur: 0.6s;

.root {
    @include spread;

    width: 100%;
    height: 100%;
    opacity: 1;
}

.isSlideShow {
    opacity: 0;
    transition: opacity $easeOutCubic $dur;

    > picture {
        position: relative;
        display: flex;
        width: 100%;
        height: 100%;
        transform: scale(1);
    }

    &.isPrev {
        opacity: 0;
        transition: opacity $easeInQuad $dur;

        > picture {
            transform: scale(1.15);
            transition-duration: var(--timer-duration);
            transition-property: transform;
            transition-timing-function: $easeOutQuad;
        }
    }

    &.isActive {
        opacity: 1;
        transition: opacity $easeOutQuad $dur;

        > picture {
            transform: scale(1.15);
            transition-duration: var(--timer-duration);
            transition-property: transform;
            transition-timing-function: $easeOutQuad;
        }
    }

    .image {
        opacity: 1;
        transform: scale(1);
        transition: none;

        .isPrev & {
            opacity: 0;
            transform: scale(1.2);
            transition: opacity $easeInQuad 0.9s, transform $easeInQuad 0.9s;
        }

        .isActive & {
            opacity: 1;
            transition: none;
        }
    }
}

.image {
    @include spread;

    width: 100%;
    max-width: none;
    height: 100%;
    object-fit: cover;
    opacity: 1;
}

@include large {
    .imageWrapper {
        align-items: flex-end;
    }

    .image {
        position: relative;
    }
}
