@import 'styles/breakpoints';
@import 'styles/mixins';

.root {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: var(--block-padding);

    &.flushTop {
        padding-top: 0;
    }

    &.flushBottom {
        padding-bottom: 0;
    }
}

.inner {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: var(--block-max-width);

    @include rounded-corners($overflow: hidden);
}

.content {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 158%;
}

.image,
.background,
.foreground {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.background {
    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 20%);
    }
}

.image {
    object-fit: cover;
    object-position: center;
}

.foreground {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: var(--block-padding);
    color: var(--colors-cream);
}

.eyebrow {
    text-transform: uppercase;
    margin-bottom: 1rem;
}

.headline {
    max-width: 36rem;
    text-transform: uppercase;
}

.line {
    display: block;
}

.cta {
    margin-top: 1.5rem;
}

@include medium {
    .content {
        padding-bottom: 56.25%;
    }

    .foreground {
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-start;
        text-align: left;
        padding: var(--block-padding-end);
        color: var(--colors-cream);
    }

    .cta {
        margin-top: 3.6rem;
    }
}

@include large {
    .headline {
        max-width: 60rem;
    }
}
