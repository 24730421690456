@import 'styles/breakpoints';

.root {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.inner {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 56rem;
    height: calc(100vh - #{var(--header-height)});
    max-height: 108rem;
}

.calloutWrapper {
    width: 100%;
    margin: var(--calloutWrapper-margin, -4.8rem 0 0 0);
    padding: var(
        --calloutWrapper-padding,
        0 var(--block-padding-side) var(--block-padding-end)
            var(--block-padding-side)
    );

    &.flushBottom {
        --calloutWrapper-padding: 0 var(--block-padding-side) 0;
    }
}

@include medium {
    .calloutWrapper {
        --calloutWrapper-margin: -7.4rem 0 0 0;
    }
}

@include large {
    .calloutWrapper {
        --calloutWrapper-margin: -9.6rem 0 0 0;
    }
}
