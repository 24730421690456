@import 'styles/breakpoints';

.blockDownloadList {
    position: relative;
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: var(--colors-blue);
}

.inner {
    display: flex;
    width: 100%;
    max-width: var(--block-max-width);
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: var(--block-padding-end) 2.4rem;
}

.eyebrow {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: var(--block-padding-end);
}

.list {
    width: 100%;
    max-width: 82.4rem;
    padding: 0;
    margin: 0;
    list-style-type: none;
}

.listItem {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    &::after {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 0;
        border-bottom: 0.1rem solid rgba(0, 30, 96, 20%);
        content: '';
    }

    &:first-child {
        &::before {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 0;
            border-bottom: 0.1rem solid rgba(0, 30, 96, 20%);
            content: '';
        }
    }
}

@include medium {
    .inner {
        padding: var(--block-padding-end) 4.8rem;
    }
}
