@import 'styles/breakpoints';
@import 'styles/animations';
@import 'styles/mixins';

$constant-rate: calc(#{$slow} * var(--screenWidth, 1024) / 1024);

.root {
    --tabSize: var(--block-padding-side);

    width: 100%;
    margin-bottom: var(--block-padding-end);
    overflow-x: auto;
    scroll-snap-type: x mandatory;
}

.slide {
    position: relative;
    display: flex;
    min-width: calc(100vw - (var(--block-padding-side) * 4));
    flex-direction: column;
    margin-left: var(--block-padding-side);
    background: #0d0a0a;
    scroll-snap-align: center;
    text-align: center;

    @include rounded-corners($overflow: hidden);

    &:last-child {
        margin-right: var(--block-padding-side);
    }

    .steps & {
        background-color: var(--backgroundColor);
    }
}

.slideTab {
    display: none;
    border: none;
    background-color: transparent;
    outline: none;
}

.slideContent {
    position: relative;
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 2.4rem 2.4rem;
    color: var(--textColor);

    .slides:first-child > .slide & {
        padding: 75% 2.4rem 2.4rem;
    }
}

.coverEyebrow {
    .theme--makers-reserve & {
        color: var(--colors-makersReserveGold);
    }
}

.slideEyebrow {
    margin-bottom: 1.2rem;
    text-transform: uppercase;
}

.slideHeading {
    margin-bottom: 1.2rem;
    text-transform: uppercase;

    .theme--makers-reserve & {
        text-transform: none;
    }

    .steps & {
        margin-bottom: 2.4rem;
    }
}

.slideBody {
    max-width: 24.5rem;
    margin: 0;
}

.coverImage {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;

    > picture {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        > img {
            position: absolute;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}

.slideIconContainer {
    display: flex;
    width: 100%;
    height: 24rem;
    align-items: center;
    justify-content: center;
}

.slideIcon {
    width: 12rem;
    height: 12rem;
    color: var(--textColor);
}

.ctaButton {
    display: none;
}

.slides {
    display: flex;
}

.steps {
    display: flex;
    padding: 0;
    margin: 0;
    list-style: none;
}

.touchCta {
    position: relative;
    display: none;
    padding-top: 1.7rem;
    padding-bottom: 1.7rem;
    margin-top: 4.8rem;
    margin-bottom: 1.2rem;
    text-transform: uppercase;

    .theme--makers-reserve & {
        color: var(--colors-makersReserveGold);
    }

    &::before,
    &::after {
        position: absolute;
        right: 30%;
        left: 30%;
        display: block;
        height: 0;
        border: 1px solid var(--colors-cream);
        content: '';

        .theme--makers-reserve & {
            border: 1px solid var(--colors-makersReserveGold);
        }
    }

    &::after {
        top: 0;
    }

    &::before {
        bottom: 0;
    }
}

@media (hover: none) {
    .touchCta {
        display: block;
    }
}

@include large {
    .root {
        position: relative;
        overflow: hidden;
        width: 100%;
    }

    .slideHeading {
        margin-bottom: 1.4rem;
    }

    .slideBody {
        max-width: 38rem;
        margin: 0;
    }

    .slides {
        position: relative;
        display: block;
        overflow: hidden;

        > .slide:first-child {
            .slideTab {
                .theme--makers-reserve & {
                    color: var(--colors-makersReserveGold);
                }
            }

            background: var(--coverTabButtonColor);

            .theme--makers-reserve & {
                background: #0d0a0a;
            }
        }
    }

    .slide {
        $width: calc(100% - (var(--slideTotal, 0)) * var(--tabSize));

        position: relative;
        top: 0;
        left: 0;
        min-width: $width;
        max-width: $width;
        height: 66vh;
        min-height: 72rem;
        flex-direction: row;
        padding-left: var(--tabSize);
        margin: 0;
        border-radius: 0;

        &::before {
            position: absolute;
            z-index: -1;
            right: 0;
            left: 0;
            display: block;
            height: 100%;
            box-shadow: -5px 5px 15px rgba(0, 0, 0, 30%);
            content: '';
            opacity: 0;
            transition: opacity 0.2s;
        }

        .slideTab {
            .theme--makers-reserve {
                color: var(--colors-makersReserveGold);
            }
        }

        .steps & {
            position: absolute;
            z-index: 20;
            left: calc((var(--slide, 0)) * var(--tabSize));
            flex-direction: column;
            transform: translateX(calc(100% - var(--tabSize)));
            transition: transform $constant-rate 0.2s $easeOutExpo;

            .slideBody {
                max-width: 36rem;
            }

            &__active {
                transform: translateX(0%);
            }

            &__hover {
                transform: translateX(calc(99.5% - var(--tabSize)));
                transition: transform $default $easeOutExpo;

                &.slide__active {
                    transform: translateX(-0.5%);
                }

                + .slide::before {
                    opacity: 1;
                }
            }
        }

        &__current {
            .slideIcon {
                opacity: 1;
                transition: opacity 2s calc((#{$slow} * $constant-rate) * 0.4)
                    $easeOutExpo;
            }

            .slideContent {
                opacity: 1;
                transition: opacity 2s calc((#{$slow} * $constant-rate) * 0.4)
                    $easeOutExpo;
            }

            .slideTab {
                cursor: default;
            }

            .coverImage {
                opacity: 1;
            }
        }
    }

    .coverEyebrow {
        display: none;
    }

    .slideIconContainer {
        height: 40%;
    }

    .slideIcon {
        width: 15rem;
        height: 15rem;
        opacity: 0;
        transition: opacity 0.2s 0s;
    }

    .coverImage {
        position: absolute;
        z-index: 0;
        right: 0;
        height: 100%;
        padding-top: 0;
        opacity: 0;
        transition: opacity $fast $fast;
    }

    .slideTab {
        position: absolute;
        z-index: 2;
        top: 0;
        bottom: 0;
        left: 0;
        display: flex;
        width: var(--tabSize);
        justify-content: center;
        color: var(--titleColor);
        cursor: pointer;

        // Ensure makers reserve always has the correct color scheme
        .theme--makers-reserve & {
            --titleColor: var(--colors-makersReserveGold);
        }

        .steps & {
            .theme--makers-reserve & {
                --titleColor: var(--colors-white);
            }
        }
    }

    .slideTabText {
        height: 100%;
        text-orientation: mixed;
        text-transform: uppercase;
        transform: rotate(-180deg);
        writing-mode: vertical-rl;
    }

    .slideContent {
        width: 100%;
        opacity: 0;
        transition: opacity 0.2s 0s;

        .steps & {
            width: 100%;
            background-image: none;
        }

        .slides:first-child > .slide & {
            padding: 0 2.4rem 2.4rem;
        }
    }

    .ctaButton {
        display: block;
        margin-top: 3.2rem;
    }
}

// break to side by side content in slides
@include xxLarge {
    .slide {
        width: 100%;

        .steps & {
            flex-direction: row;
        }
    }

    .slideContent {
        width: 50%;
    }

    .slideIconContainer {
        height: 100%;
        order: 2;
    }

    .slideIcon {
        width: 23rem;
        height: 23rem;
        background-position: center;
    }
}

@include xxxLarge {
    .root {
        --tabSize: 12.6rem;
    }
}
