@import 'styles/animations';
@import 'styles/breakpoints';

$dur: 0.6s;

.carouselShowcaseSlide {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: var(--colors-blue);
}

.imageWrapper {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    width: var(--imageWrapper-width, 100%);
    height: 100%;
    align-items: flex-start;
    justify-content: center;
    opacity: 0;
    transform: scale(0.9);
    transition: opacity $easeOutCubic $dur, transform $easeOutCubic $dur;

    .isPrev & {
        opacity: 0;
        transform: scale(1.1);
        transition: opacity $easeInQuad $dur, transform $easeInQuad $dur;
    }

    .isActive & {
        opacity: 1;
        transform: scale(1);
        transition: opacity $easeOutQuad 0.9s 0.57s,
            transform $easeOutQuad 0.9s 0.57s;
    }
}

.imageWrapperInner {
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    transform: scale(0.88);
    transition-property: transform;
    transition-timing-function: $easeOutQuad;

    .isActive & {
        transform: scale(1);
    }
}

.image {
    width: 100%;
    max-width: none;
    height: auto;
}

.column {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    width: var(--column-width, 100%);
    height: var(--column-height, 50%);
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &:last-child {
        top: var(--column-top, 50%);
        right: 0;
        left: auto;
    }
}

.textLockup {
    position: absolute;
    width: var(--textLockup-width, 100%);
    text-transform: uppercase;
}

.headline {
    padding-bottom: var(--headline-padding-bottom, 26rem);
    margin-top: 2rem;
}

.headlineLineWrap,
.eyebrowWrap {
    position: relative;
    overflow: hidden;
}

.eyebrow {
    transform: translateY(100%);
    transition: transform linear 1.1s;
    visibility: hidden;

    .isPrev & {
        transform: translateY(-100%);
        transition: transform linear 1.1s;
        visibility: visible;
    }

    .isActive & {
        transform: translateY(0%);
        transition: transform linear 1.1s;
        visibility: visible;
    }
}

.headlineLine {
    transform: translateY(100%);
    transition: transform $easeOutCubic $dur;
    visibility: hidden;

    .isPrev & {
        transform: translateY(-100%);
        transition: transform $easeInQuad $dur;
        visibility: visible;
    }

    .isActive & {
        transform: translateY(0%);
        transition: transform $easeOutQuad $dur 0.57s;
        visibility: visible;
    }
}

@include medium {
    .carouselShowcaseSlide {
        --headline-padding-bottom: 12rem;
    }
}

@include large {
    .carouselShowcaseSlide {
        --column-width: 50%;
        --column-height: 100%;
        --column-top: 0;
    }

    .column {
        transform: translateX(19%);

        &:last-child {
            transform: translateX(3%);
        }
    }

    .imageWrapper {
        align-items: center;
    }
}

@include xxLarge {
    .carouselShowcaseSlide {
        --headline-padding-bottom: 13rem;
    }
}
