@import 'styles/breakpoints';

.root {
    width: 100%;
    padding: var(--block-padding);
    background-color: var(--background-color);

    &.flushTop {
        padding-top: 0;
    }

    &.flushBottom {
        padding-bottom: 0;
    }

    &.lastBlock {
        padding-bottom: var(--last-block-padding-bottom);
    }
}

.miniGrid {
    padding: 0;
    background-color: var(--colors-white);
}

.eyebrow {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    padding: var(--block-padding);
    padding-top: 0;
    color: var(--colors-blue);
}

.gridContent {
    display: flex;
    max-width: var(--block-max-width);
    flex-direction: column;
    margin-right: auto;
    margin-left: auto;
    gap: 4.8rem;
}
