@import 'styles/breakpoints';

.goal {
    grid-column: 1 / -1;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: var(--goal-padding, 0 0 4rem 0);
    max-width: var(--goal-max-width, 35rem);
    margin: 0 auto;
}

.subHeadline {
    text-transform: uppercase;
}

.headline {
    padding: var(--headline-padding, 1rem 0);
}

.imageBlock {
    margin: var(--imgWrapper-margin, 0 0 2rem 0);
    width: 10.6rem;
    height: 10.6rem;

    .img {
        object-fit: cover;
        object-position: center;
    }
}

@include medium {
    .goal {
        text-align: left;
        align-items: flex-start;
        justify-content: flex-start;
        --goal-padding: 0 0 9.7rem 0;
        --headline-padding: 2rem 0;
        margin: 0;
        &:nth-child(even) {
            grid-column: 1 / span 4;
        }
        &:nth-child(odd) {
            grid-column: 5 / span 4;
        }
    }
}

@include large {
    .goal {
        --goal-max-width: 43.2rem;
        &:nth-child(even) {
            grid-column: 2 / span 5;
        }
        &:nth-child(odd) {
            grid-column: 7 / span 5;
        }
    }
}
