@import 'styles/breakpoints';

.spinnerWrapper {
    position: relative;
}

.image {
    height: 44.5rem;
    overflow: hidden;
    user-select: none;
    cursor: grab;

    img {
        margin: 0 auto;
        pointer-events: none;
    }
}

.buttonWrapper {
    position: absolute;
    bottom: 0;
    width: 100%;
    background-color: var(--colors-white);
    pointer-events: none;
}

.button {
    margin: 2.4rem auto;
    background-color: var(--colors-orange);
    height: 5rem;
    border-radius: 10rem;
    padding: 0 3rem;
}

.buttonIcon {
    margin: 0 1.6rem 0 0;

    svg {
        width: 3rem;
        height: 0.85rem;
    }
}

.arrowRight {
    margin: 0 0 0 1.6rem;
}

@include medium {
    .image {
        height: 66.5rem;
    }

    .buttonWrapper {
        background-color: transparent;
    }
}

@include large {
    .buttonWrapper {
        button {
            margin: 4.8rem auto;
        }
    }
}

@include xxLarge {
    .image {
        height: 72rem;
    }
}
