@import 'styles/animations';
@import 'styles/breakpoints';
@import 'styles/colors';

.root {
    position: absolute;
    overflow: hidden;
    width: 100%;
    height: auto;
    aspect-ratio: 1/1;
    background-color: #ffcf84;

    &::before {
        position: absolute;
        bottom: -15%;
        left: 0;
        width: 100%;
        height: 20%;
        background-color: #ffe3b7;
        content: '';
    }
}

.top,
.cheese,
.bottom {
    position: absolute;

    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}

.top {
    top: -8%;
    width: 100%;
    height: 42%;
}

.cheese {
    top: 24%;
    width: 100%;
    height: 26%;
    transform-origin: bottom;
}

.bottom {
    bottom: 0;
    width: 100%;
    height: 53%;
}

@include medium {
    .root {
        height: 100%;
        aspect-ratio: unset;
    }

    .top {
        top: -1%;
    }

    .cheese {
        top: 30%;
        transform: scaleY(1.1);
    }
}

@include large {
    .root {
        height: auto;
        aspect-ratio: 1/1;
    }

    .top {
        top: -8%;
        width: 100%;
        height: 42%;
    }

    .cheese {
        top: 24%;
        width: 100%;
        height: 26%;
        transform-origin: bottom;
    }

    .bottom {
        bottom: 0;
        width: 100%;
        height: 53%;
    }
}
