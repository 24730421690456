@import 'styles/animations';
@import 'styles/breakpoints';
@import 'styles/colors';
@import 'styles/mixins';

.root {
    position: relative;
    display: flex;
    overflow: hidden;
    width: 100%;
    height: 100%;
    margin: var(--root-margin, var(--block-padding-end) 0);
    color: var(--colors-cream);

    &.flushTop {
        margin-top: 0;
    }

    &.flushBottom {
        margin-bottom: 0;
    }
}

.inner {
    position: relative;
    z-index: 3;
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.slides {
    position: relative;
    width: 100%;
    aspect-ratio: 0.8;
}

.cards {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: var(--column-background-color, var(--colors-white));
    color: var(--colors-blue);
}

.cardWrapper {
    position: relative;
    height: calc(var(--cards-height, 100%));
    transition: height $default $easeInOut;
    width: 100%;
    margin: 0 auto;
}

.navWrapper {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: flex-end;
    justify-content: center;
    padding-bottom: var(--nav-padding-bottom, 4.8rem);
    color: var(--colors-blue);
}

.nav {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.navCount {
    width: 2.6rem;
    margin: var(--nav-margin, 0 2.1rem 0 2.2rem);
    white-space: pre;
}

.navItem {
    display: flex;
    align-items: center;
    justify-content: center;
}

@include medium {
    .slides {
        aspect-ratio: 1.9;
    }
}

@include large {
    .root {
        --column-background-color: transparent;
        --nav-margin: 2.4rem 0;
        --root-margin: 6.9rem 0;

        height: 50vw;
        min-height: 40rem;
    }

    .cardWrapper {
        position: relative;
        display: flex;
        width: 100%;
        max-width: var(--block-max-width-plus-padding);
        height: 100%;
        align-items: center;
        justify-content: flex-end;
        padding-right: var(--block-padding-side);
    }

    .navWrapper {
        &-cream {
            color: var(--colors-cream);
        }
    }

    .nav {
        position: absolute;
        top: 50%;
        left: 5%;
        flex-direction: column;
        transform: translate(0, -50%);
    }

    .slides {
        @include spread;
    }
}

@include xxLarge {
    .root {
        --root-margin: 9.6rem 0;
    }
}

@include xxxLarge {
    .root {
        --root-margin: 9.6rem 0;
    }
}
