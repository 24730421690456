@import 'styles/breakpoints';

.gridRow {
    position: relative;
    display: flex;
    width: 100%;
    flex-direction: var(--gridRow-flex-direction, column);
    justify-content: center;
    color: var(--colors-blue);
    gap: 4.8rem;
}

.gridItem {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: flex-start;
    justify-content: center;
}

.lockup {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: var(--lockup-padding, 0 10%);
}

.headline {
    max-width: 25rem;
    text-align: center;
    text-transform: uppercase;
}

.copy {
    max-width: 40rem;
    margin: var(--copy-margin, 2rem 0 0);
    text-align: center;
}

.iconBox {
    display: flex;
    width: var(--icon-box-width, 7rem);
    height: 7rem;
    align-items: center;
    justify-content: center;
    margin: var(--icon-box-margin, 0 0 2rem 0);
}

.iconWrapper {
    position: relative;
    width: 6rem;
    height: 6rem;

    &.blueIcon {
        color: var(--colors-blue);
    }
}

.imageContainer {
    position: relative;
    overflow: hidden;
    width: 100%;
    border-radius: 2rem;
    margin: 0 0 2.4rem;
    aspect-ratio: 16/9;
}

.image {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.cta {
    margin-top: 2.4rem;
}

@include medium {
    .gridRow {
        --gridRow-flex-direction: row;
    }

    .gridThreeItem {
        --lockup-padding: 0 1.4rem;
    }

    .gridItem {
        width: 30rem;
        margin-bottom: 0;
    }

    .headline {
        font-size: clamp(1.2rem, 0rem + 1.563vw, 1.6rem);
    }

    .copy {
        font-size: clamp(1.2rem, 0rem + 1.563vw, 1.6rem);

        p {
            font-size: clamp(1.2rem, 0rem + 1.563vw, 1.6rem);
        }
    }
}

@include large {
    .gridThreeItem {
        --lockup-padding: 0 4.8rem;
    }
}
